import React from 'react';

/**
 * ImageGrid Component
 * 
 * This component displays a grid of images or placeholder elements if no images are available.
 * It's used to show the generated designs in the NewDesign component.
 *
 * @param {Object} props
 * @param {string[]} props.images - An array of image URLs to display
 * @param {function} props.onImageClick - Callback function to handle image click events
 */
const ImageGrid = ({ images, onImageClick }) => {
  return (
    <div className="grid grid-cols-2 gap-4 h-full">
      {images.length > 0
        ? images.map((imageUrl, index) => (
            // Render actual images if available
            <div 
              key={index} 
              className="flex items-center justify-center min-h-[150px] cursor-pointer" 
              onClick={() => onImageClick(imageUrl, index)}
            >
              <img 
                src={imageUrl} 
                alt={`Generated image ${index + 1}`} 
                className="w-full h-full object-contain rounded-lg"
              />
            </div>
          ))
        : Array.from({ length: 4 }).map((_, index) => (
            // Render placeholder elements if no images are available
            <div 
              key={index} 
              className="flex items-center justify-center min-h-[150px] bg-white dark:bg-gray-800 rounded-lg relative overflow-hidden"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-[#CF08FF] to-[#3E86FF]"></div>
              <div className="absolute inset-[3px] bg-white dark:bg-gray-800 rounded-lg flex items-center justify-center">
                <p className="text-gray-300 dark:text-gray-600 text-2xl text-center">
                  Your dezygns will show here 😊
                </p>
              </div>
            </div>
          ))
      }
    </div>
  );
};

export default ImageGrid;