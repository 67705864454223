import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const API_URL = process.env.REACT_APP_WP_API_URL;

const Billing = ({ isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    const initStripe = async () => {
      if (!STRIPE_PUBLISHABLE_KEY) {
        console.error('Stripe publishable key is not set');
        return;
      }
      try {
        const stripeInstance = await loadStripe(STRIPE_PUBLISHABLE_KEY);
        setStripe(stripeInstance);
        console.log('Stripe initialized successfully');
      } catch (error) {
        console.error('Error initializing Stripe:', error);
      }
    };
    initStripe();
  }, []);

  const plans = [
    { name: 'Content Writer', credits: 500, monthlyPrice: 49, yearlyPrice: 353 },
    { name: 'Copywriter', credits: 1000, monthlyPrice: 89, yearlyPrice: 641 },
    { name: 'Freelancer', credits: 2000, monthlyPrice: 179, yearlyPrice: 1289 },
  ];

  const handleCheckout = async (plan, isYearly) => {
    console.log('handleCheckout called', { plan, isYearly });
    if (!stripe) {
      console.error('Stripe has not been initialized yet');
      return;
    }

    setLoading(true);

    try {
      console.log('Fetching from API:', `${API_URL}/create-checkout-session`);
      // Call your backend to create a Checkout Session
      const response = await fetch(`${API_URL}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          plan: plan.name,
          isYearly,
        }),
      });

      console.log('API response:', response);
      const session = await response.json();
      console.log('Session data:', session);

      // Redirect to Stripe Checkout
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.error('Stripe redirectToCheckout error:', result.error.message);
      }
    } catch (error) {
      console.error('Error in checkout:', error);
    } finally {
      setLoading(false);
    }
  };

  const openBillingPortal = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/create-portal-session`, {
        method: 'POST',
      });
      const { url } = await response.json();
      window.location.href = url;
    } catch (error) {
      console.error('Error opening billing portal:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="bg-white p-5 rounded-lg shadow-xl max-w-4xl w-full">
        <h2 className="text-2xl font-bold mb-4">Upgrade Your Plan</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {plans.map((plan) => (
            <div key={plan.name} className="border p-4 rounded-lg">
              <h3 className="text-xl font-semibold">{plan.name}</h3>
              <p>{plan.credits} Credits/Tokens</p>
              <p className="mt-2">
                Monthly: ${plan.monthlyPrice}
                <button
                  onClick={() => handleCheckout(plan, false)}
                  className="ml-2 bg-blue-500 text-white px-2 py-1 rounded"
                  disabled={loading || !stripe}
                >
                  Select
                </button>
              </p>
              <p>
                Yearly: ${plan.yearlyPrice} (save 20%)
                <button
                  onClick={() => handleCheckout(plan, true)}
                  className="ml-2 bg-green-500 text-white px-2 py-1 rounded"
                  disabled={loading || !stripe}
                >
                  Select
                </button>
              </p>
            </div>
          ))}
        </div>
        <div className="mt-4">
          <button
            onClick={openBillingPortal}
            className="bg-gray-200 px-4 py-2 rounded"
            disabled={loading}
          >
            Manage Billing
          </button>
          <button
            onClick={onClose}
            className="ml-4 bg-red-500 text-white px-4 py-2 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Billing;