import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function ProtectedRoute({ children }) {
  const { isLoggedIn, isLoading, userUsage, userPlan } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  if (userUsage && userPlan && location.pathname === '/new-design') {
    const currentUsage = userUsage.total_images || 0;
    const usageLimit = parseInt(userPlan.tokens_per_month) || 0;

    if (currentUsage >= usageLimit) {
      return <Navigate to="/settings" state={{ from: location, usageLimitExceeded: true }} />;
    }
  }

  return children;
}

export default ProtectedRoute;