import React from 'react';
import ImageSizeSelector from '../ImageSizeSelector';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

const AdvancedSettings = ({ imageSize, setImageSize, numImages, setNumImages, userPlan, userUsage }) => {
  const navigate = useNavigate();

  const handleHelpClick = () => {
    navigate('/settings');
  };

  return (
    <div className="mt-2 p-2 bg-gray-100 dark:bg-gray-700 rounded-md space-y-4">
      <ImageSizeSelector
        selectedSize={imageSize}
        onSizeChange={setImageSize}
        hideLabels={true}
      />
      <div>
        <label htmlFor="numImages" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Number of Images: {numImages}
        </label>
        <input
          type="range"
          id="numImages"
          min="1"
          max="4"
          value={numImages}
          onChange={(e) => setNumImages(parseInt(e.target.value))}
          className="w-full"
        />
      </div>
      {userPlan && userUsage && (
        <div className="mt-4 text-sm text-gray-600 dark:text-gray-400 flex items-center">
          <p>Tokens used: {userUsage.generations_count} / {userPlan.tokens_per_month}</p>
          <QuestionMarkCircleIcon
            className="h-5 w-5 ml-2 cursor-pointer text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
            onClick={handleHelpClick}
            aria-label="Help"
          />
        </div>
      )}
    </div>
  );
};

export default AdvancedSettings;