import React from 'react';

const Notification = ({ message, type }) => {
  if (!message) return null;

  return (
    <div className={`fixed bottom-4 right-4 px-4 py-2 rounded-md text-white ${type === 'error' ? 'bg-red-500' : 'bg-green-500'}`}>
      {message}
    </div>
  );
};

export default Notification;