import React from 'react';
import CreateTab from './CreateTab';
import AssistantTab from './AssistantTab';

/**
 * ControlPanel Component
 * 
 * This component manages the control panel in the NewDesign interface.
 * It includes brand selection, tab switching between Create and Assistant modes,
 * and renders the appropriate tab content.
 *
 * @param {Object} props
 * @param {string} props.activeTab - The currently active tab ('create' or 'assistant')
 * @param {function} props.setActiveTab - Function to set the active tab
 * @param {string} props.brand - The currently selected brand
 * @param {function} props.setBrand - Function to set the selected brand
 * @param {Array} props.brands - List of available brands
 * @param {function} props.handleCopyBrand - Function to copy the brand brief
 * @param {Object} props.userPlan - User's plan details (passed to CreateTab and AdvancedSettings)
 * @param {Object} props.userUsage - User's usage statistics (passed to CreateTab and AdvancedSettings)
 * @param {Object} props.props - Additional props to pass to the CreateTab component
 */
const ControlPanel = ({ activeTab, setActiveTab, brand, setBrand, brands, handleCopyBrand, userPlan, userUsage, ...props }) => {
  return (
    <div className="flex flex-col h-full">
      {/* Brand selection dropdown */}
      <div className="mb-4 flex items-center justify-between">
        <select
          value={brand}
          onChange={(e) => setBrand(e.target.value)}
          className="text-sm border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
        >
          <option value="">Select brand</option>
          {brands.map((brand) => (
            <option key={brand.id} value={brand.title}>{brand.title}</option>
          ))}
        </select>
        {/* Copy brand brief button */}
        {brand && (
          <button
            onClick={handleCopyBrand}
            className="px-3 py-1 text-sm border border-transparent rounded-md text-indigo-600 dark:text-indigo-400 hover:bg-indigo-100 dark:hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Copy brand brief
          </button>
        )}
      </div>
     
      {/* Tab switching buttons */}
      <div className="flex mb-4">
        <button 
          onClick={() => setActiveTab('create')}
          className={`px-4 py-2 ${activeTab === 'create' ? 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200' : 'bg-gray-100 dark:bg-gray-600 text-gray-600 dark:text-gray-400'} rounded-tl-md rounded-bl-md font-medium`}
        >
          Create
        </button>
        <button 
          onClick={() => setActiveTab('assistant')}
          className={`px-4 py-2 ${activeTab === 'assistant' ? 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200' : 'bg-gray-100 dark:bg-gray-600 text-gray-600 dark:text-gray-400'} rounded-tr-md rounded-br-md font-medium`}
        >
          Assistant
        </button>
      </div>
      {/* Tab content */}
      <div className="flex-grow overflow-y-auto">
        {activeTab === 'create' ? <CreateTab {...props} userPlan={userPlan} userUsage={userUsage} /> : <AssistantTab />}
      </div>
    </div>
  );
};

export default ControlPanel;