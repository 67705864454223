import axios from 'axios';

const authAxios = axios.create({
  withCredentials: true
});

authAxios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('jwt_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

authAxios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('jwt_token');
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default authAxios;