import React, { useState, useCallback, useEffect, useRef } from 'react';
import { XMarkIcon, ArrowUpIcon, ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { getBrands } from '../api/wordpress';
import Draggable from 'react-draggable';

// Available transformation options
const TRANSFORMATIONS = [
  { value: 'none', label: 'None' },
  { value: 'logo-overlay', label: 'Logo Overlay' },
];

/**
 * TransformModal Component
 * 
 * This component provides a modal interface for applying image transformations.
 * It supports logo overlay operations.
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.isOpen - Controls the visibility of the modal
 * @param {function} props.onClose - Function to close the modal
 * @param {string} props.imageUrl - URL of the image to be transformed
 */
const TransformModal = ({ isOpen, onClose, imageUrl }) => {
  // State management for transformation options and results
  const [selectedTransformation, setSelectedTransformation] = useState('none');
  const [transformedImageUrl, setTransformedImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // State for logo overlay
  const [selectedBrand, setSelectedBrand] = useState('None');
  const [logoType, setLogoType] = useState('color');
  const [overlayPosition, setOverlayPosition] = useState({ x: 30, y: 30 }); // Changed initial position to 30px from top and left
  const [brands, setBrands] = useState([]);
  const [overlaySize, setOverlaySize] = useState(15);

  const imageRef = useRef(null);
  const overlayRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    fetchBrands();
  }, []);

  const fetchBrands = async () => {
    try {
      const fetchedBrands = await getBrands();
      setBrands([{ id: 'none', title: 'None', logo: '', white_logo: '' }, ...fetchedBrands]);
    } catch (err) {
      console.error('Error fetching brands:', err);
    }
  };

  // Reset state when a new image is loaded
  useEffect(() => {
    setTransformedImageUrl(null);
    setSelectedTransformation('none');
    setSelectedBrand('None');
    setLogoType('color');
    setOverlayPosition({ x: 30, y: 30 }); // Reset to 30px from top and left
    setOverlaySize(15);
  }, [imageUrl]);

  /**
   * Function to overlay a logo
   * 
   * @param {string} baseImageUrl - URL of the base image
   * @param {string} overlayImageUrl - URL of the overlay image
   * @param {Object} position - Position of the overlay image
   * @param {number} size - Size of the overlay image as a percentage
   * @returns {Promise<string>} - URL of the processed image
   */
  const overlayLogo = useCallback(async (baseImageUrl, overlayImageUrl, position, size) => {
    try {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const baseImage = await loadImage(baseImageUrl);
      canvas.width = baseImage.width;
      canvas.height = baseImage.height;
      ctx.drawImage(baseImage, 0, 0);

      const overlayImage = await loadImage(overlayImageUrl);
      const scaleFactor = size / 100;
      const scaledWidth = overlayImage.width * scaleFactor;
      const scaledHeight = overlayImage.height * scaleFactor;
      ctx.drawImage(overlayImage, position.x, position.y, scaledWidth, scaledHeight);

      return canvas.toDataURL();
    } catch (error) {
      console.error('Error overlaying logo:', error);
      throw error;
    }
  }, []);

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  };

  useEffect(() => {
    const applyTransformation = async () => {
      if (selectedTransformation === 'logo-overlay' && selectedBrand !== 'None') {
        setIsLoading(true);
        setError(null);
        try {
          const overlayImageUrl = logoType === 'color'
            ? brands.find(b => b.title === selectedBrand)?.logo
            : brands.find(b => b.title === selectedBrand)?.white_logo;
          
          if (overlayImageUrl) {
            const result = await overlayLogo(imageUrl, overlayImageUrl, overlayPosition, overlaySize);
            setTransformedImageUrl(result);
          }
        } catch (err) {
          setError(err.message || 'Failed to apply transformation. Please try again.');
        } finally {
          setIsLoading(false);
        }
      } else {
        setTransformedImageUrl(null);
      }
    };

    applyTransformation();
  }, [selectedTransformation, selectedBrand, logoType, overlayPosition, overlaySize, brands, imageUrl, overlayLogo]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-4 max-w-4xl w-full max-h-[90vh] flex flex-col">
        <ModalHeader onClose={onClose} transformedImageUrl={transformedImageUrl} originalImageUrl={imageUrl} />
        <div className="flex flex-col md:flex-row h-full">
          <ControlPanel
            selectedTransformation={selectedTransformation}
            setSelectedTransformation={setSelectedTransformation}
            selectedBrand={selectedBrand}
            setSelectedBrand={setSelectedBrand}
            logoType={logoType}
            setLogoType={setLogoType}
            brands={brands}
            overlayPosition={overlayPosition}
            setOverlayPosition={setOverlayPosition}
            overlaySize={overlaySize}
            setOverlaySize={setOverlaySize}
          />
          <ImagePreview
            transformedImageUrl={transformedImageUrl}
            originalImageUrl={imageUrl}
            selectedBrand={selectedBrand}
            logoType={logoType}
            overlayPosition={overlayPosition}
            setOverlayPosition={setOverlayPosition}
            overlaySize={overlaySize}
            imageRef={imageRef}
            overlayRef={overlayRef}
            containerRef={containerRef}
            brands={brands}
            isLoading={isLoading}
          />
        </div>
        {error && <ErrorMessage message={error} />}
      </div>
    </div>
  );
};

/**
 * ModalHeader Component
 * 
 * Renders the header of the modal, including the title, Download button, Edit in Youzign button, and close button.
 * 
 * @param {Object} props - Component props
 * @param {function} props.onClose - Function to close the modal
 * @param {string} props.transformedImageUrl - URL of the transformed image
 * @param {string} props.originalImageUrl - URL of the original image
 */
const ModalHeader = ({ onClose, transformedImageUrl, originalImageUrl }) => {
  const handleDownload = () => {
    if (transformedImageUrl) {
      const link = document.createElement('a');
      link.href = transformedImageUrl;
      link.download = 'dezygn.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(transformedImageUrl, '_blank');
    }
  };

  return (
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-xl font-semibold">Transformation</h2>
      <div>
        {transformedImageUrl && (
          <button
            onClick={handleDownload}
            className="mr-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Download
          </button>
        )}
        <button
          onClick={() => window.open('https://youzign.com', '_blank')}
          className="mr-2 px-4 py-2 bg-white text-blue-600 border border-blue-600 rounded-md hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Edit in Youzign
        </button>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <XMarkIcon className="h-6 w-6" />
        </button>
      </div>
    </div>
  );
};

/**
 * ControlPanel Component
 * 
 * Renders the control panel for selecting and applying transformations.
 * 
 * @param {Object} props - Component props
 * @param {string} props.selectedTransformation - Currently selected transformation
 * @param {function} props.setSelectedTransformation - Function to update selected transformation
 * @param {string} props.selectedBrand - Currently selected brand
 * @param {function} props.setSelectedBrand - Function to update selected brand
 * @param {string} props.logoType - Currently selected logo type
 * @param {function} props.setLogoType - Function to update selected logo type
 * @param {Array} props.brands - List of available brands
 * @param {Object} props.overlayPosition - Position of the overlay image
 * @param {function} props.setOverlayPosition - Function to update overlay position
 * @param {number} props.overlaySize - Size of the overlay image
 * @param {function} props.setOverlaySize - Function to update overlay size
 */
const ControlPanel = ({
  selectedTransformation,
  setSelectedTransformation,
  selectedBrand,
  setSelectedBrand,
  logoType,
  setLogoType,
  brands,
  overlayPosition,
  setOverlayPosition,
  overlaySize,
  setOverlaySize,
}) => (
  <div className="w-full md:w-1/4 pr-4 mb-4 md:mb-0">
    <div className="mb-4">
      <label htmlFor="transformation" className="block text-sm font-medium text-gray-700 mb-2">
        Transformation
      </label>
      <select
        id="transformation"
        value={selectedTransformation}
        onChange={(e) => setSelectedTransformation(e.target.value)}
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
      >
        {TRANSFORMATIONS.map((transform) => (
          <option key={transform.value} value={transform.value}>
            {transform.label}
          </option>
        ))}
      </select>
    </div>

    {selectedTransformation === 'logo-overlay' && (
      <>
        <div className="mb-4">
          <label htmlFor="brand" className="block text-sm font-medium text-gray-700 mb-2">
            Select brand
          </label>
          <select
            id="brand"
            value={selectedBrand}
            onChange={(e) => setSelectedBrand(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          >
            {brands.map((brand) => (
              <option key={brand.id} value={brand.title}>{brand.title}</option>
            ))}
          </select>
        </div>
        {selectedBrand !== 'None' && (
          <div className="mb-4">
            <div className="flex items-center mb-2">
              <input
                type="radio"
                id="color_logo"
                name="logoType"
                value="color"
                checked={logoType === 'color'}
                onChange={(e) => setLogoType(e.target.value)}
                className="mr-2"
              />
              <label htmlFor="color_logo" className="text-sm text-gray-700">
                Color logo
              </label>
            </div>
            <div className="flex items-center mb-2">
              <input
                type="radio"
                id="white_logo"
                name="logoType"
                value="white"
                checked={logoType === 'white'}
                onChange={(e) => setLogoType(e.target.value)}
                className="mr-2"
              />
              <label htmlFor="white_logo" className="text-sm text-gray-700">
                White logo
              </label>
            </div>
          </div>
        )}
        <div className="mt-4">
          <h3 className="text-sm font-medium text-gray-700 mb-2">Overlay Controls</h3>
          <div className="flex flex-wrap justify-between mb-2">
            <button
              onClick={() => setOverlayPosition(prev => ({ ...prev, y: prev.y - 10 }))}
              className="p-2 bg-gray-200 rounded-full hover:bg-gray-300"
            >
              <ArrowUpIcon className="h-4 w-4" />
            </button>
            <button
              onClick={() => setOverlayPosition(prev => ({ ...prev, y: prev.y + 10 }))}
              className="p-2 bg-gray-200 rounded-full hover:bg-gray-300"
            >
              <ArrowDownIcon className="h-4 w-4" />
            </button>
            <button
              onClick={() => setOverlayPosition(prev => ({ ...prev, x: prev.x - 10 }))}
              className="p-2 bg-gray-200 rounded-full hover:bg-gray-300"
            >
              <ArrowLeftIcon className="h-4 w-4" />
            </button>
            <button
              onClick={() => setOverlayPosition(prev => ({ ...prev, x: prev.x + 10 }))}
              className="p-2 bg-gray-200 rounded-full hover:bg-gray-300"
            >
              <ArrowRightIcon className="h-4 w-4" />
            </button>
          </div>
          <div className="mt-4">
            <label htmlFor="size-slider" className="block text-sm font-medium text-gray-700 mb-2">
              Overlay Size: {overlaySize}%
            </label>
            <input
              type="range"
              id="size-slider"
              min="10"
              max="100"
              value={overlaySize}
              onChange={(e) => setOverlaySize(Number(e.target.value))}
              className="w-full"
            />
          </div>
        </div>
      </>
    )}
  </div>
);

/**
 * ImagePreview Component
 * 
 * Renders the image preview, showing either the original or transformed image.
 * 
 * @param {Object} props - Component props
 * @param {string} props.transformedImageUrl - URL of the transformed image
 * @param {string} props.originalImageUrl - URL of the original image
 * @param {string} props.selectedBrand - Currently selected brand
 * @param {string} props.logoType - Currently selected logo type
 * @param {Object} props.overlayPosition - Position of the overlay image
 * @param {function} props.setOverlayPosition - Function to update overlay position
 * @param {number} props.overlaySize - Size of the overlay image
 * @param {Object} props.imageRef - Reference to the main image element
 * @param {Object} props.overlayRef - Reference to the overlay image element
 * @param {Object} props.containerRef - Reference to the container element
 * @param {Array} props.brands - List of available brands
 * @param {boolean} props.isLoading - Indicates if a transformation is in progress
 */
const ImagePreview = ({
  transformedImageUrl,
  originalImageUrl,
  selectedBrand,
  logoType,
  overlayPosition,
  setOverlayPosition,
  overlaySize,
  imageRef,
  overlayRef,
  containerRef,
  brands,
  isLoading,
}) => {
  const [bounds, setBounds] = useState({});
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (containerRef.current && imageRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const imageRect = imageRef.current.getBoundingClientRect();

      setBounds({
        left: 0,
        top: 0,
        right: containerRect.width - imageRect.width,
        bottom: containerRect.height - imageRect.height
      });
    }
  }, [transformedImageUrl, originalImageUrl]);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragStop = (e, data) => {
    setIsDragging(false);
    setOverlayPosition({ x: data.x, y: data.y });
  };

  const handleDrag = (e, data) => {
    if (!isDragging) return;
    setOverlayPosition({ x: data.x, y: data.y });
  };

  const getOverlayImageUrl = () => {
    const selectedBrandObject = brands.find(b => b.title === selectedBrand);
    return logoType === 'color' ? selectedBrandObject?.logo : selectedBrandObject?.white_logo;
  };

  const overlayImageUrl = getOverlayImageUrl();

  return (
    <div ref={containerRef} className="w-full md:w-3/4 bg-gray-100 rounded-lg flex flex-col items-center justify-center overflow-hidden relative">
      {isLoading ? (
        <div className="text-gray-500">Loading...</div>
      ) : transformedImageUrl || originalImageUrl ? (
        <>
          <img
            ref={imageRef}
            src={transformedImageUrl || originalImageUrl}
            alt="Transformed"
            className="max-w-full max-h-full object-contain"
            crossOrigin="anonymous"
          />
          {overlayImageUrl && !transformedImageUrl && (
            <Draggable
              bounds={bounds}
              position={overlayPosition}
              onStart={handleDragStart}
              onDrag={handleDrag}
              onStop={handleDragStop}
            >
              <img
                ref={overlayRef}
                src={overlayImageUrl}
                alt="Overlay"
                className="absolute cursor-move"
                crossOrigin="anonymous"
                style={{ 
                  opacity: isDragging ? 0.6 : 1,
                  maxWidth: `${overlaySize}%`,
                  maxHeight: `${overlaySize}%`
                }}
              />
            </Draggable>
          )}
        </>
      ) : (
        <div className="text-gray-500">No image available</div>
      )}
    </div>
  );
};

/**
 * ErrorMessage Component
 * 
 * Renders an error message.
 * 
 * @param {Object} props - Component props
 * @param {string} props.message - The error message to display
 */
const ErrorMessage = ({ message }) => (
  <p className="text-red-500 mt-2">{message}</p>
);

export default TransformModal;