import React, { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import ImageSizeSelector from '../ImageSizeSelector';
import AdvancedSettings from './AdvancedSettings';

/**
 * CreateTab Component
 * 
 * This component renders the Create tab in the NewDesign interface.
 * It includes a textarea for entering the design prompt, advanced settings,
 * and a button to create the design.
 *
 * @param {Object} props
 * @param {string} props.prompt - The current design prompt
 * @param {function} props.setPrompt - Function to update the prompt
 * @param {string} props.imageSize - The selected image size
 * @param {function} props.setImageSize - Function to update the image size
 * @param {number} props.numImages - The number of images to generate
 * @param {function} props.setNumImages - Function to update the number of images
 * @param {function} props.handleCreateDesign - Function to handle design creation
 * @param {boolean} props.isGenerating - Flag indicating if a design is currently being generated
 * @param {Object} props.userPlan - User's plan details
 * @param {Object} props.userUsage - User's usage statistics
 */
const CreateTab = ({ prompt, setPrompt, imageSize, setImageSize, numImages, setNumImages, handleCreateDesign, isGenerating, userPlan, userUsage }) => {
  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState(false);

  return (
    <div className="flex flex-col h-full">
      {/* Prompt input textarea */}
      <div className="flex-grow overflow-y-auto">
        <textarea
          id="prompt"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Enter what you want to see"
          className="w-full h-full px-3 py-2 text-sm border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 resize-none bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
        />
      </div>
      {/* Advanced settings toggle */}
      <div className="mt-4">
        <button
          onClick={() => setIsAdvancedSettingsOpen(!isAdvancedSettingsOpen)}
          className="flex items-center text-sm text-indigo-600 dark:text-indigo-400 hover:text-indigo-800 dark:hover:text-indigo-300"
        >
          Advanced settings
          <ChevronDownIcon className={`ml-1 h-4 w-4 transform ${isAdvancedSettingsOpen ? 'rotate-180' : ''}`} />
        </button>
        {isAdvancedSettingsOpen && (
          <AdvancedSettings
            imageSize={imageSize}
            setImageSize={setImageSize}
            numImages={numImages}
            setNumImages={setNumImages}
            userPlan={userPlan}
            userUsage={userUsage}
          />
        )}
      </div>
      {/* Create design button */}
      <button 
        onClick={handleCreateDesign}
        disabled={isGenerating}
        className="w-full mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 flex items-center justify-center"
      >
        {isGenerating ? (
          <>
            <svg className="animate-spin h-5 w-5 mr-3 text-white" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
            Creating...
          </>
        ) : 'Create'}
      </button>
    </div>
  );
};

export default CreateTab;