import React, { useRef, useEffect, useState } from 'react';
import { XMarkIcon, ChevronLeftIcon, ChevronRightIcon, ArrowsPointingOutIcon, ArrowsPointingInIcon, BookmarkIcon, ArrowDownTrayIcon } from '@heroicons/react/24/solid';

/**
 * ImageModal Component
 * 
 * This component displays a modal with an enlarged view of a selected image.
 * It provides functionality for fullscreen viewing, navigation between images,
 * and options to save or download the displayed image.
 *
 * @param {Object} props
 * @param {string} props.image - URL of the image to display
 * @param {function} props.onClose - Function to close the modal
 * @param {function} props.onSave - Function to save the image
 * @param {function} props.onDownload - Function to download the image
 * @param {number} props.currentIndex - Current index of the displayed image
 * @param {number} props.totalImages - Total number of images
 * @param {function} props.onPrev - Function to navigate to the previous image
 * @param {function} props.onNext - Function to navigate to the next image
 */
const ImageModal = ({ image, onClose, onSave, onDownload, currentIndex, totalImages, onPrev, onNext }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const fullscreenRef = useRef(null);

  // Effect to handle fullscreen changes
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  /**
   * Toggles fullscreen mode for the image
   */
  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      fullscreenRef.current.requestFullscreen().catch((err) => {
        console.error(`Error attempting to enable fullscreen: ${err.message}`);
      });
    } else {
      document.exitFullscreen();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className={`bg-white dark:bg-gray-800 rounded-lg p-4 max-w-3xl max-h-[90vh] w-full flex flex-col ${isFullscreen ? 'fixed inset-0 z-50' : ''}`}>
        <div className="flex justify-between items-center mb-4">
          <span className="text-gray-500 dark:text-gray-400">
            Image {currentIndex + 1} of {totalImages}
          </span>
          <button onClick={onClose} className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200">
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        <div ref={fullscreenRef} className="flex-grow flex items-center justify-center overflow-hidden relative">
          <img 
            src={image} 
            alt="Larger version" 
            className="max-w-full max-h-[70vh] object-contain rounded-lg shadow-md hover:shadow-lg border border-gray-200 bg-white transition-all duration-300 ease-in-out"
          />
          {/* Fullscreen toggle button */}
          <button
            onClick={toggleFullscreen}
            className="absolute top-2 right-2 p-1 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-75 transition-opacity duration-300"
          >
            {isFullscreen ? (
              <ArrowsPointingInIcon className="h-6 w-6" />
            ) : (
              <ArrowsPointingOutIcon className="h-6 w-6" />
            )}
          </button>
          {/* Navigation buttons */}
          <button
            onClick={onPrev}
            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white dark:bg-gray-800 rounded-full p-1 shadow-md"
          >
            <ChevronLeftIcon className="h-6 w-6 text-gray-600 dark:text-gray-400" />
          </button>
          <button
            onClick={onNext}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white dark:bg-gray-800 rounded-full p-1 shadow-md"
          >
            <ChevronRightIcon className="h-6 w-6 text-gray-600 dark:text-gray-400" />
          </button>
        </div>
        {/* Action buttons */}
        <div className="mt-4 flex justify-end space-x-4">
          <button
            onClick={() => onSave(image)}
            className="px-4 py-2 bg-[#3E86FF] text-white rounded-md hover:bg-[#3373DA] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2D67C1] disabled:opacity-50 flex items-center justify-center"
          >
            <BookmarkIcon className="h-5 w-5 mr-2" />
            Save
          </button>
          <button
            onClick={() => onDownload(image)}
            className="px-4 py-2 bg-[#CF08FF] text-white rounded-md hover:bg-[#B007E0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#A106CC] flex items-center justify-center"
          >
            <ArrowDownTrayIcon className="h-5 w-5 mr-2" />
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;