import React from 'react';

/**
 * AssistantTab Component
 * 
 * This component renders the Assistant tab in the NewDesign interface.
 * It embeds an external chat interface (Answerly) to provide assistance
 * to users during the design process.
 */
const AssistantTab = () => {
  return (
    <div className="h-full flex flex-col">
      {/* Embedded Answerly chat interface */}
      <iframe 
        src="https://answerly.chat/fdf30648-78e5-4d7e-b2ba-d6e04908c64a" 
        className="w-full flex-grow"
        style={{height: 'calc(100% - 40px)', borderRadius: '15px'}}
        title="Assistant"
      ></iframe>
    </div>
  );
};

export default AssistantTab;