import React from 'react';
import squareIcon from '../assets/square.png';
import verticalLongIcon from '../assets/Vertical_long.png';
import verticalWideIcon from '../assets/Vertical_wide.png';
import horizontalLongIcon from '../assets/Horizontal_long.png';
import horizontalWideIcon from '../assets/Horizontal_wide.png';

const imageSizes = [
  { id: 'landscape_4_3', name: 'Horizontal 4:3', icon: horizontalWideIcon, aspectRatio: 'aspect-[4/3]' },
  { id: 'landscape_16_9', name: 'Horizontal 16:9', icon: horizontalLongIcon, aspectRatio: 'aspect-[16/9]' },
  { id: 'square', name: 'Square', icon: squareIcon, aspectRatio: 'aspect-square' },
  { id: 'portrait_4_3', name: 'Vertical 4:3', icon: verticalWideIcon, aspectRatio: 'aspect-[3/4]' },
  { id: 'portrait_16_9', name: 'Vertical 16:9', icon: verticalLongIcon, aspectRatio: 'aspect-[9/16]' }
];

const ImageSizeOption = ({ size, isSelected, onSelect, hideLabels }) => {
  return (
    <div 
      onClick={() => onSelect(size.id)}
      className={`
        flex flex-col items-center p-2 border rounded-lg cursor-pointer
        transition-all duration-200 ease-in-out
        ${isSelected ? 'bg-blue-100 dark:bg-blue-900 border-blue-500' : 'hover:bg-gray-100 dark:hover:bg-gray-700'}
      `}
    >
      <div className={`w-16 h-16 relative ${size.aspectRatio}`}>
        <img src={size.icon} alt={size.name} className="w-full h-full object-contain" />
      </div>
      {!hideLabels && <span className="mt-1 text-xs text-center text-gray-700 dark:text-gray-300">{size.name}</span>}
    </div>
  );
};

const ImageSizeSelector = ({ selectedSize, onSizeChange, hideLabels }) => {
  return (
    <div className="w-full">
      <h3 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-200">Format</h3>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {imageSizes.map((size) => (
          <ImageSizeOption
            key={size.id}
            size={size}
            isSelected={selectedSize === size.id}
            onSelect={onSizeChange}
            hideLabels={hideLabels}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageSizeSelector;