import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import { getGenerations } from '../api/wordpress';
import { useAuth } from '../context/AuthContext';
import { ClipboardIcon } from '@heroicons/react/24/outline';
import Notification from './NewDesign/Notification'; // Import the Notification component

function History() {
  const [generations, setGenerations] = useState([]);
  const [filteredGenerations, setFilteredGenerations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isLoggedIn, checkLoginStatus } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDate, setSelectedDate] = useState('');
  const [showNotification, setShowNotification] = useState(false); // Add state for notification
  const itemsPerPage = 10;

  useEffect(() => {
    if (isLoggedIn) {
      fetchGenerations();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    filterGenerations();
  }, [selectedDate, generations]);

  const fetchGenerations = async () => {
    try {
      setLoading(true);
      const response = await getGenerations();
      setGenerations(response);
      setFilteredGenerations(response);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching generations:', error);
      setError('Failed to load generation history. Please try again later.');
      setLoading(false);
      if (error.response && error.response.status === 401) {
        await checkLoginStatus();
      }
    }
  };

  const filterGenerations = () => {
    if (!selectedDate) {
      setFilteredGenerations(generations);
    } else {
      const filtered = generations.filter(generation => {
        const generationDate = new Date(generation.date);
        const filterDate = new Date(selectedDate);
        return generationDate.toDateString() === filterDate.toDateString();
      });
      setFilteredGenerations(filtered);
    }
    setCurrentPage(1);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const copyPrompt = (prompt) => {
    navigator.clipboard.writeText(prompt).then(() => {
      setShowNotification(true); // Show notification
      setTimeout(() => setShowNotification(false), 3000); // Hide notification after 3 seconds
    }).catch(err => {
      console.error('Failed to copy prompt: ', err);
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;

    return `Dezygned on ${year}/${month}/${day} at ${formattedHours}:${minutes} ${ampm}`;
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredGenerations.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredGenerations.length / itemsPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const totalPageButtons = 7; // Total number of page buttons to show
    const sidePages = 2; // Number of pages to show on each side of the current page

    if (totalPages <= totalPageButtons) {
      // If total pages are less than or equal to totalPageButtons, show all pages
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Always include first page
      pageNumbers.push(1);

      if (currentPage <= sidePages + 3) {
        // If current page is close to the start
        for (let i = 2; i <= sidePages + 3; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      } else if (currentPage >= totalPages - (sidePages + 2)) {
        // If current page is close to the end
        pageNumbers.push('...');
        for (let i = totalPages - (sidePages + 3); i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        // Current page is somewhere in the middle
        pageNumbers.push('...');
        for (let i = currentPage - sidePages; i <= currentPage + sidePages; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };

  if (loading) {
    return <div className="flex-grow p-4 dark:text-gray-200">Loading...</div>;
  }

  if (error) {
    return <div className="flex-grow p-4 text-red-500 dark:text-red-400">{error}</div>;
  }

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100 dark:bg-gray-900">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 dark:bg-gray-900">
          <div className="container mx-auto px-4 sm:px-6 py-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-gray-700 dark:text-gray-200 text-2xl font-medium">History</h3>
              <div className="relative max-w-sm">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                  <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                  </svg>
                </div>
                <input 
                  type="date" 
                  id="default-datepicker" 
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                  placeholder="Select date"
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </div>
            </div>
            <div className="bg-white dark:bg-gray-700 rounded-lg shadow-md overflow-hidden">
              <div className="space-y-8 p-6">
                {currentItems.map((generation) => (
                  <div key={generation.id} className="bg-gray-50 dark:bg-gray-800 rounded-lg shadow-md p-6 relative">
                    <div className="absolute top-2 left-2 bg-gray-800 dark:bg-gray-600 text-white text-xs px-2 py-1 rounded">
                      {formatDate(generation.date)}
                    </div>
                    <button
                      onClick={() => copyPrompt(generation.design_prompt)}
                      className="absolute top-2 right-2 px-3 py-1.5 bg-blue-600 text-white text-sm rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 flex items-center"
                    >
                      <ClipboardIcon className="h-4 w-4 mr-1.5" />
                      Copy prompt
                    </button>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-8">
                      {[1, 2, 3, 4].map((num) => {
                        const imageUrl = generation[`image_${num}_url`];
                        return imageUrl ? (
                          <a
                            key={num}
                            href={imageUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block w-full h-full"
                          >
                            <img 
                              src={imageUrl} 
                              alt={`Generated image ${num}`} 
                              className="w-full h-auto rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200"
                            />
                          </a>
                        ) : null;
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* Pagination Controls */}
            <nav aria-label="Page navigation" className="mt-8">
              <ul className="inline-flex -space-x-px text-sm">
                <li>
                  <button
                    onClick={() => paginate(currentPage - 1)}
                    className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>
                {getPageNumbers().map((number, index) => (
                  <li key={index}>
                    {number === '...' ? (
                      <span className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400">
                        ...
                      </span>
                    ) : (
                      <button
                        onClick={() => paginate(number)}
                        aria-current={currentPage === number ? "page" : undefined}
                        className={`flex items-center justify-center px-3 h-8 leading-tight ${
                          currentPage === number
                            ? "text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                            : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        }`}
                      >
                        {number}
                      </button>
                    )}
                  </li>
                ))}
                <li>
                  <button
                    onClick={() => paginate(currentPage + 1)}
                    className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </main>
      </div>
      {showNotification && (
        <Notification
          message="Prompt copied to clipboard!"
          type="success"
          onClose={() => setShowNotification(false)}
        />
      )}
    </div>
  );
}

export default History;