import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import DesignList from './DesignList';
import { SunIcon, MoonIcon } from '@heroicons/react/24/solid';

function Dashboard() {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    // Check if dark mode is enabled
    if (localStorage.getItem('darkMode') === 'true') {
      setDarkMode(true);
      document.documentElement.classList.add('dark');
    }
  }, []);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    if (darkMode) {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('darkMode', 'false');
    } else {
      document.documentElement.classList.add('dark');
      localStorage.setItem('darkMode', 'true');
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100 dark:bg-gray-900">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden bg-gray-100 dark:bg-gray-900">
          <div className="container mx-auto px-4 sm:px-6 py-4">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
              <h3 className="text-gray-700 dark:text-gray-200 text-2xl font-medium mb-2 sm:mb-0">Designs</h3>
              <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
                <button
                  onClick={toggleDarkMode}
                  className="bg-white dark:bg-gray-800 rounded-full p-1 flex items-center justify-center shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                >
                  <div className={`w-12 h-6 flex items-center rounded-full p-1 relative ${darkMode ? 'bg-gray-800' : 'bg-gray-200'}`}>
                    <div className={`absolute transition-transform duration-300 ease-in-out transform ${darkMode ? 'translate-x-6' : 'translate-x-0'}`}>
                      {darkMode ? (
                        <MoonIcon className="h-4 w-4 text-gray-300" />
                      ) : (
                        <SunIcon className="h-4 w-4 text-yellow-500" />
                      )}
                    </div>
                  </div>
                </button>
                <Link 
                  to="/new-design" 
                  className="bg-[#CF08FF] hover:bg-[#B007E6] text-white font-bold py-2 px-4 rounded w-full sm:w-auto text-center transition-colors duration-200"
                >
                  New Design
                </Link>
              </div>
            </div>
            <div className="bg-white dark:bg-gray-700 rounded-lg shadow-md p-4">
              <DesignList />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;