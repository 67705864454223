import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'; // Add this import
import { getSavedDesigns } from '../api/wordpress';
import { useAuth } from '../context/AuthContext';
import { PencilIcon, ArrowDownIcon, ClipboardIcon } from '@heroicons/react/24/outline';
import TransformModal from './TransformModal';

function DesignList() {
  const [designs, setDesigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isLoggedIn, checkLoginStatus } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [copiedNotification, setCopiedNotification] = useState(false);
  const navigate = useNavigate(); // Add this line

  const fetchDesigns = useCallback(async () => {
    if (!isLoggedIn) {
      setError('Please log in to view your saved designs.');
      setLoading(false);
      return;
    }

    try {
      const fetchedDesigns = await getSavedDesigns();
      setDesigns(fetchedDesigns);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching saved designs:', err);
      if (err.response && err.response.status === 401) {
        const refreshed = await checkLoginStatus();
        if (refreshed) {
          fetchDesigns();
        } else {
          setError('Your session has expired. Please log in again.');
        }
      } else {
        setError('Failed to load saved designs. Please try again later.');
      }
      setLoading(false);
    }
  }, [isLoggedIn, checkLoginStatus]);

  useEffect(() => {
    fetchDesigns();
  }, [fetchDesigns]);

  const handleDownloadClick = useCallback((e, fullImageUrl) => {
    e.stopPropagation();
    window.open(fullImageUrl, '_blank', 'noopener,noreferrer');
  }, []);

  const handleEditClick = useCallback((e, designId, imageUrl) => {
    e.stopPropagation();
    setSelectedImageUrl(imageUrl);
    setIsModalOpen(true);
  }, []);

  const handleCopyPrompt = useCallback((e, design) => {
    e.stopPropagation();
    const prompt = design.design_prompt;
    if (prompt) {
      navigator.clipboard.writeText(prompt).then(() => {
        setCopiedNotification(true);
        setTimeout(() => {
          setCopiedNotification(false);
        }, 2000);
      }).catch(err => {
        console.error('Failed to copy prompt: ', err);
      });
    } else {
      console.error('No prompt available for this design');
    }
  }, []);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
    setSelectedImageUrl(null);
  }, []);

  const handleNewBrandClick = useCallback(() => {
    navigate('/new-brand'); // Navigate to the NewBrand page
  }, [navigate]);

  if (loading) {
    return <div className="flex-grow p-4 dark:text-gray-200">Loading...</div>;
  }

  if (error) {
    return <div className="flex-grow p-4 text-red-500 dark:text-red-400">{error}</div>;
  }

  // Empty state
  if (designs.length === 0) {
    return (
      <div className="flex-grow p-4 flex flex-col items-center justify-center">
        <img 
          src="https://i.imgur.com/7ew9vYc.png" 
          alt="Welcome to Dezygn" 
          className="w-[500px] h-[300px] object-cover mb-6"
        />
        <h2 className="text-2xl font-bold mb-2">Welcome to Dezygn V2 Beta 🚀</h2>
        <p className="text-gray-600 mb-4">Get started by watching a tutorial.</p>
        <a 
  href="https://komododecks.com/recordings/oRdGXeZB4aTZfRYxj8uN?onlyRecording=1" target="_blank"
  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded inline-block no-underline"
>
  Watch Tutorial
</a>
      </div>
    );
  }

  return (
    <div className="flex-grow p-4 dark:bg-gray-900">
      {copiedNotification && (
        <div className="fixed top-4 right-4 bg-green-500 text-white px-4 py-2 rounded shadow-lg z-50">
          Copied!
        </div>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 auto-rows-[200px]">
        {designs.map((design) => {
          const thumbnailUrl = design.thumbnail_url || design.image_url;
          return (
            <div 
              key={design.id} 
              className="relative bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden cursor-pointer group"
            >
              {thumbnailUrl ? (
                <img 
                  src={thumbnailUrl} 
                  alt={design.title} 
                  className="w-full h-full object-cover"
                  loading="lazy"
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center text-gray-500 dark:text-gray-400">
                  No image available
                </div>
              )}
              <div className="absolute bottom-2 right-2 flex space-x-2 opacity-0 group-hover:opacity-100 transition-opacity">
                <DesignButton
                  onClick={(e) => handleEditClick(e, design.id, design.image_url)}
                  icon={<PencilIcon className="h-5 w-5" />}
                  tooltip="Edit"
                />
                <DesignButton
                  onClick={(e) => handleCopyPrompt(e, design)}
                  icon={<ClipboardIcon className="h-5 w-5" />}
                  tooltip="Copy prompt"
                />
                <DesignButton
                  onClick={(e) => handleDownloadClick(e, design.image_url)}
                  icon={<ArrowDownIcon className="h-5 w-5" />}
                  tooltip="Get"
                />
              </div>
            </div>
          );
        })}
      </div>
      <TransformModal 
        isOpen={isModalOpen}
        onClose={handleModalClose}
        imageUrl={selectedImageUrl}
      />
    </div>
  );
}

function DesignButton({ onClick, icon, tooltip }) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <div 
      className="relative"
      onMouseEnter={() => setIsTooltipVisible(true)}
      onMouseLeave={() => setIsTooltipVisible(false)}
    >
      <button
        onClick={onClick}
        className="p-2 bg-gray-500 bg-opacity-60 hover:bg-opacity-80 rounded-full transition-colors"
        aria-label={tooltip}
      >
        <span className="text-white">{icon}</span>
      </button>
      {isTooltipVisible && (
        <span className="absolute bottom-full right-1/2 transform translate-x-1/2 mb-2 bg-black text-white text-xs rounded py-1 px-2 whitespace-nowrap z-10">
          {tooltip}
        </span>
      )}
    </div>
  );
}

export default DesignList;