import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import { createBrand } from '../api/wordpress';

function NewBrand() {
  const [brandData, setBrandData] = useState({
    name: '',
    style_suffix: '',
    logoUrl: '',
    whiteLogoUrl: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBrandData({ ...brandData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    console.log('Submitting brand data:', brandData);
    
    try {
      const response = await createBrand(brandData);
      console.log('API response:', response);
      
      if (response && response.id) {
        setSuccessMessage('Brand successfully created!');
        setErrorMessage('');
        console.log('Brand created successfully. Redirecting to /brands');
        // Redirect to the Brands page immediately after successful creation
        navigate('/brands');
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error creating brand:', error);
      let errorMsg = 'Error creating brand. Please try again.';
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
        errorMsg = error.response.data.message || errorMsg;
      } else if (error.request) {
        console.error('Error request:', error.request);
        errorMsg = 'No response received from server. Please try again.';
      } else {
        console.error('Error message:', error.message);
        errorMsg = error.message;
      }
      setErrorMessage(errorMsg);
      setSuccessMessage('');
    }
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit} className="bg-white dark:bg-gray-700 rounded-lg shadow-md p-6 sm:p-8 max-w-4xl mx-auto">
      <div className="space-y-8 divide-y divide-gray-200 dark:divide-gray-600">
        {/* Brand Name */}
        <div className="pb-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100 mb-2">Brand Name</h3>
          <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">The name of your brand, e.g. Coca-Cola.</p>
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              value={brandData.name}
              onChange={handleInputChange}
              className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
            />
          </div>
        </div>

        {/* Brand Brief */}
        <div className="pt-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100 mb-2">Brand Brief</h3>
          <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">A summary of your brand identity used for creating visuals.</p>
          <div>
            <label htmlFor="style_suffix" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Brand Brief</label>
            <textarea
              name="style_suffix"
              id="style_suffix"
              rows="5"
              value={brandData.style_suffix}
              onChange={handleInputChange}
              className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
            />
          </div>
        </div>

        {/* Logo URLs */}
        <div className="pt-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100 mb-2">Logo URLs</h3>
          <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">Please host your logo images on imgur.com (<a href="https://komododecks.com/recordings/e3t7fwx8VAg5VqLgzU0V" target="_blank" rel="noopener noreferrer">tutorial</a>)</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="logoUrl" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Logo URL</label>
              <input
                type="url"
                name="logoUrl"
                id="logoUrl"
                value={brandData.logoUrl}
                onChange={handleInputChange}
                className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                placeholder="https://i.imgur.com/logo.png"
              />
            </div>
            <div>
              <label htmlFor="whiteLogoUrl" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">White Logo URL</label>
              <input
                type="url"
                name="whiteLogoUrl"
                id="whiteLogoUrl"
                value={brandData.whiteLogoUrl}
                onChange={handleInputChange}
                className="block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                placeholder="https://i.imgur.com/white_logo.png"
              />
            </div>
          </div>
        </div>
        
        {/* Save Button */}
        <div className="pt-6 flex justify-end">
          <button
            type="submit"
            className="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );

  const renderSidebar = () => (
    <aside className="w-80 bg-gray-50 dark:bg-gray-800 p-4 border-l border-gray-200 dark:border-gray-700 overflow-hidden flex flex-col">
      <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">Brand Assistant</h2>
      <div className="flex-grow overflow-hidden">
        <iframe 
          src="https://answerly.chat/7a72fdf4-f956-4d61-9fd0-a82af551e8b1" 
          className="w-full h-full"
          style={{border: 'none', borderRadius: '15px'}}
          title="Assistant"
        ></iframe>
      </div>
    </aside>
  );

  return (
    <div className="flex h-screen bg-gray-100 dark:bg-gray-900">
      <Sidebar />
      <div className="flex-1 flex overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white dark:bg-gray-800">
          <div className="container mx-auto px-4 sm:px-6 py-8">
            {successMessage && (
              <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-6" role="alert">
                <span className="block sm:inline">{successMessage}</span>
              </div>
            )}
            {errorMessage && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-6" role="alert">
                <span className="block sm:inline">{errorMessage}</span>
              </div>
            )}
            {renderForm()}
          </div>
        </main>
        {renderSidebar()}
      </div>
    </div>
  );
}

export default NewBrand;