import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import { getBrands, updateBrand, deleteBrand } from '../api/wordpress';
import { useAuth } from '../context/AuthContext';
import { PencilIcon, TrashIcon, XMarkIcon, CheckIcon } from '@heroicons/react/24/outline';

const EditBrandModal = ({ brand, onClose, onUpdate, onDelete }) => {
  const [brandData, setBrandData] = useState({
    name: brand.title,
    styleSuffix: brand.style_suffix,
    logoUrl: brand.logo,
    whiteLogoUrl: brand.white_logo,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(`Input changed: ${name} = ${value}`);
    setBrandData({ ...brandData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Starting brand update process...');
    console.log('Current brand data:', brandData);
    console.log('Brand ID:', brand.id);
    try {
      console.log('Calling updateBrand API...');
      const response = await updateBrand(brand.id, brandData);
      console.log('Brand updated successfully. API response:', response);
      onUpdate();
      onClose();
    } catch (error) {
      console.error('Error updating brand:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
    }
    console.log('Brand update process completed');
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this brand?')) {
      console.log('Starting brand delete process...');
      try {
        console.log('Calling deleteBrand API...');
        const response = await deleteBrand(brand.id);
        console.log('Brand deleted successfully. API response:', response);
        onDelete();
        onClose();
      } catch (error) {
        console.error('Error deleting brand:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
          console.error('Error status:', error.response.status);
          console.error('Error headers:', error.response.headers);
        } else if (error.request) {
          console.error('Error request:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
      }
      console.log('Brand delete process completed');
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-3/4 max-w-3xl shadow-lg rounded-md bg-white dark:bg-gray-800">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
        <div className="mt-3">
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100 text-center">Edit Brand</h3>
          <form onSubmit={handleSubmit} className="mt-2">
            <div className="mt-2">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 text-left">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                value={brandData.name}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100"
              />
            </div>
            <div className="mt-2">
              <label htmlFor="styleSuffix" className="block text-sm font-medium text-gray-700 dark:text-gray-300 text-left">Brand Brief</label>
              <textarea
                name="styleSuffix"
                id="styleSuffix"
                value={brandData.styleSuffix}
                onChange={handleInputChange}
                rows="8"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100"
              ></textarea>
            </div>
            <div className="mt-2">
              <label htmlFor="logoUrl" className="block text-sm font-medium text-gray-700 dark:text-gray-300 text-left">Logo URL</label>
              <input
                type="url"
                name="logoUrl"
                id="logoUrl"
                value={brandData.logoUrl}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100"
              />
            </div>
            <div className="mt-2">
              <label htmlFor="whiteLogoUrl" className="block text-sm font-medium text-gray-700 dark:text-gray-300 text-left">White Logo URL</label>
              <input
                type="url"
                name="whiteLogoUrl"
                id="whiteLogoUrl"
                value={brandData.whiteLogoUrl}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100"
              />
            </div>
            <div className="mt-4 flex justify-start space-x-2">
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 flex items-center"
              >
                <CheckIcon className="h-5 w-5 mr-2" />
                Save
              </button>
              <button
                onClick={handleDelete}
                type="button"
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 flex items-center"
              >
                <TrashIcon className="h-5 w-5 mr-2" />
                Delete Brand
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const Brands = () => {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isLoggedIn, isLoading, checkLoginStatus } = useAuth();
  const [editingBrand, setEditingBrand] = useState(null);

  useEffect(() => {
    if (isLoggedIn) {
      fetchBrands();
    }
  }, [isLoggedIn]);

  const fetchBrands = async () => {
    console.log('Fetching brands...');
    try {
      const fetchedBrands = await getBrands();
      console.log('Fetched brands. API response:', fetchedBrands);
      setBrands(fetchedBrands);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching brands:', err);
      setError('Failed to load brands. Please try again later.');
      setLoading(false);
      if (err.response && err.response.status === 401) {
        checkLoginStatus();
      }
    }
    console.log('Fetch brands process completed');
  };

  const handleEditClick = (brand) => {
    console.log('Editing brand:', brand);
    setEditingBrand(brand);
  };

  const handleDeleteClick = async (brand) => {
    if (window.confirm('Are you sure you want to delete this brand?')) {
      try {
        await deleteBrand(brand.id);
        console.log('Brand deleted successfully');
        fetchBrands();
      } catch (error) {
        console.error('Error deleting brand:', error);
        setError('Failed to delete brand. Please try again later.');
      }
    }
  };

  const handleCloseModal = () => {
    console.log('Closing edit modal');
    setEditingBrand(null);
  };

  const handleUpdateBrand = () => {
    console.log('Brand updated, refreshing brand list');
    fetchBrands();
  };

  const handleDeleteBrand = () => {
    console.log('Brand deleted, refreshing brand list');
    fetchBrands();
  };

  if (isLoading) {
    return <div className="text-center mt-8 dark:text-gray-200">Loading...</div>;
  }

  if (!isLoggedIn) {
    console.log('User not logged in, redirecting to login page');
    return <Navigate to="/login" />;
  }

  if (loading) return <div className="text-center mt-8 dark:text-gray-200">Loading brands...</div>;
  if (error) return <div className="text-center mt-8 text-red-500 dark:text-red-400">{error}</div>;

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100 dark:bg-gray-900">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden bg-gray-100 dark:bg-gray-900">
          <div className="container mx-auto px-4 sm:px-6 py-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-gray-700 dark:text-gray-200 text-2xl font-medium">Brands</h3>
              <Link to="/new-brand" className="bg-[#CF08FF] hover:bg-[#B007E6] text-white font-bold py-2 px-4 rounded">
                New Brand
              </Link>
            </div>
            {error && (
              <div className="bg-red-100 border border-red-400 text-red-700 dark:bg-red-900 dark:border-red-700 dark:text-red-100 px-4 py-3 rounded relative mb-4" role="alert">
                <span className="block sm:inline">{error}</span>
              </div>
            )}
            <div className="bg-white dark:bg-gray-700 rounded-lg shadow-md overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 dark:border-gray-600 bg-gray-100 dark:bg-gray-800 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 dark:border-gray-600 bg-gray-100 dark:bg-gray-800 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider">
                      Logo
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 dark:border-gray-600 bg-gray-100 dark:bg-gray-800 text-left text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {brands.map((brand) => (
                    <tr key={brand.id}>
                      <td className="px-5 py-5 border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-700 text-sm">
                        <div className="flex items-center">
                          <div className="ml-3">
                            <p className="text-gray-900 dark:text-gray-100 whitespace-no-wrap">
                              {brand.title}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-700 text-sm">
                        {brand.logo && (
                          <img 
                            src={brand.logo} 
                            alt={`${brand.title} logo`} 
                            className="h-16 w-auto"
                          />
                        )}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-700 text-sm">
                        <button
                          onClick={() => handleEditClick(brand)}
                          className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-2 rounded mr-2"
                        >
                          <PencilIcon className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(brand)}
                          className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-2 rounded"
                        >
                          <TrashIcon className="h-5 w-5" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </div>
      {editingBrand && (
        <EditBrandModal
          brand={editingBrand}
          onClose={handleCloseModal}
          onUpdate={handleUpdateBrand}
          onDelete={handleDeleteBrand}
        />
      )}
    </div>
  );
};

export default Brands;